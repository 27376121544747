import { getStoreValue } from '../../stores/store/utils';
import { stores } from '../../stores';
import { getPaybackAvailableProgram } from '../../microservices/payback/casino-payback';
import { logger } from '../logger';
import { isFeatureAvailable } from '../feature';
import { FEATURE } from '../types';
import { casinoPaybackStore } from '../../stores/casino-payback';
import { ActiveProgram } from '../../types/casino-payback/types';
import { CasinoGame, CasinoGameLauncher } from './types';

export async function loadCasinoPaybackProgram() {
    try {
        const isAuthenticated = getStoreValue(stores.isAuthenticated);
        let casinoPayback = getStoreValue(casinoPaybackStore.casinoPayback);

        if (isAuthenticated && isFeatureAvailable(FEATURE.CASINO_PAYBACK)) {
            if (casinoPayback && new Date(casinoPayback.expiresAt) < new Date()) {
                casinoPayback = null;
            } else {
                casinoPayback = await getPaybackAvailableProgram();
            }
            casinoPaybackStore.casinoPayback.set(casinoPayback);
        }
    } catch (error) {
        logger.error('CasinoCasinoPaybackService', 'loadCasinoPaybackProgram', error);
    }
}

export function getPaybackGames(
    paybackProgram: ActiveProgram,
    gamesByGroupId: { [key: number]: CasinoGame },
    gamesById: { [key: number]: CasinoGame },
) {
    const games = paybackProgram.gameGroupIds?.length
        ? paybackProgram.gameGroupIds.map((gameGroupId) => gamesByGroupId[gameGroupId])
        : paybackProgram.gameIds?.map((gameId) => gamesById[gameId]);
    return games?.filter(Boolean) ?? [];
}

export function isGameInPayback(
    paybackProgram: ActiveProgram,
    gameLauncher: CasinoGameLauncher,
    gamesByServerId: { [key: number]: CasinoGame },
) {
    return (
        gameLauncher?.serverGameId &&
        (paybackProgram.gameGroupIds?.includes(gamesByServerId[gameLauncher.serverGameId]?.groupId) ||
            paybackProgram.gameIds?.includes(gamesByServerId[gameLauncher.serverGameId]?.id))
    );
}
