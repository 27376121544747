import { createStores } from './store/utils';

export const twoFactor = createStores({
    smsPinId: undefined as string | undefined,
    reason: undefined as string | undefined,
    safePhone: undefined as string | undefined,
    email: undefined as string | undefined,
    smsResendFailedReason: undefined as string | undefined,
    isTwoFactorSmsActive: false,
    isTwoFactorEmailActive: false,
    country: '',
    shouldAskToTrustDevice: undefined as boolean | undefined,
});
