import keyBy from 'lodash/keyBy';
import { getFavoriteGames } from '../../microservices/casino-preferences';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { casinoPaybackStore } from '../../stores/casino-payback';
import { getStoreValue } from '../../stores/store/utils';
import { Jackpot } from '../../types/casino-thrilltech-jackpot/types';
import { isSIM } from '../environment';
import { isFeatureAvailable } from '../feature';
import { getRoute } from '../router';
import { translate } from '../translate';
import { FEATURE } from '../types';
import { GameLabels } from './game-label';
import { mapCasinoGames } from './games';
import {
    CasinoCategory,
    CasinoCategoryCode,
    CasinoCategoryTypes,
    CasinoCustomCategoryIds,
    CasinoGame,
    CasinoRace,
} from './types';
import { loadCasinoCategories } from '../../microservices/casino';
import { getPaybackGames } from './casino-payback';

export function mapCasinoCategories(raceGames: CasinoGame[]): void {
    let categories = getStoreValue(casino.categories);
    insertCustomCategories(categories, raceGames);
    const gamesById = getStoreValue(casino.gamesById);

    categories.forEach((category) => {
        category.games = category.games.filter((gameId) => gamesById[gameId]);
    });
    categories = categories.filter((category) => category.games.length);
    casino.categories.set(categories);
    casino.categoriesById.set(keyBy(categories, 'id'));
    casino.categoriesByCode.set(keyBy(categories, 'code'));
    casino.categoriesBySlug.set(keyBy(categories, (category) => getCasinoCategorySlug(category.id)));
}

function insertCustomCategories(categories: CasinoCategory[], raceGames: CasinoGame[]) {
    const categoryCodes = categories.map(({ code }) => code);
    if (isFeatureAvailable(FEATURE.RECENTLY_PLAYED_GAMES)) {
        const recentlyPlayedCategory: CasinoCategory = getRecentlyPlayedCategory();
        const shouldInsertRecentlyPlayed = !categoryCodes.includes(CasinoCategoryCode.RECENTLY_PLAYED);

        if (shouldInsertRecentlyPlayed) {
            categories.push(recentlyPlayedCategory);
        }
    }

    const races = getStoreValue(stores.casinoRace.active.races);
    const raceCategory = getCasinoRaceCategory(races, raceGames);
    const shouldInsertRaceCategory = raceCategory && !categoryCodes.includes(CasinoCategoryCode.RACE);
    if (shouldInsertRaceCategory) {
        categories.push(raceCategory);
    }

    const casinoPaybackCategory = getCasinoPaybackCategory();
    if (casinoPaybackCategory) {
        categories.push(casinoPaybackCategory);
    }

    const favoriteGamesCategory = getFavoriteCategory();
    if (favoriteGamesCategory && !categoryCodes.includes(CasinoCategoryCode.FAVORITE_GAMES)) {
        categories.push(favoriteGamesCategory);
    }
}

function getFavoriteCategory(): CasinoCategory | null {
    const favoriteGameIds = getStoreValue(casino.userPreferences.favoriteGameIds);

    if (!favoriteGameIds.length) {
        return null;
    }

    return {
        id: CasinoCustomCategoryIds.FAVORITE_GAMES,
        name: 'Favorite games',
        code: CasinoCategoryCode.FAVORITE_GAMES,
        order: 97,
        type: CasinoCategoryTypes.CASINO,
        games: favoriteGameIds,
        isCustom: true,
        filters: [],
    };
}

function getRecentlyPlayedCategory() {
    const recentlyPlayedGames = getStoreValue(casino.recentlyPlayedGames);
    const recentlyPlayedCategory: CasinoCategory = {
        id: CasinoCustomCategoryIds.RECENTLY_PLAYED,
        name: 'Recently played games',
        code: CasinoCategoryCode.RECENTLY_PLAYED,
        order: 98,
        type: CasinoCategoryTypes.CASINO,
        games: recentlyPlayedGames.map((recentlyPlayedGame) => recentlyPlayedGame.id),
        isCustom: true,
        filters: [],
    };
    return recentlyPlayedCategory;
}

function getCasinoRaceCategory(races: Record<number, CasinoRace>, raceGames: CasinoGame[]): CasinoCategory | null {
    if (!races) {
        return null;
    }

    const raceCategory: CasinoCategory = {
        id: CasinoCustomCategoryIds.CASINO_RACE,
        name: 'Casino race games',
        code: CasinoCategoryCode.RACE,
        order: 99,
        type: CasinoCategoryTypes.CASINO,
        games: raceGames.map((raceGame) => raceGame.id),
        isCustom: true,
        filters: [],
    };
    return raceCategory;
}

function getCasinoPaybackCategory(): CasinoCategory | null {
    const casinoPayback = getStoreValue(casinoPaybackStore.casinoPayback);
    const gamesByGroupId = getStoreValue(casino.gamesByGroupId);
    const gamesById = getStoreValue(casino.gamesById);

    if (!casinoPayback) {
        return null;
    }

    const casinoPaybackGames = getPaybackGames(casinoPayback, gamesByGroupId, gamesById);
    const casinoPaybackCategory: CasinoCategory = {
        id: CasinoCustomCategoryIds.CASINO_PAYBACK,
        name: 'Casino payback',
        code: CasinoCategoryCode.CASINO_PAYBACK,
        order: 101,
        type: CasinoCategoryTypes.CASINO,
        games: casinoPaybackGames.map((game) => game.id) || [],
        isCustom: true,
        filters: [],
    };

    return casinoPaybackCategory;
}

export function getCasinoCategoryRoute({ id, type }: CasinoCategory): string {
    if (isSIM()) {
        return `${getRoute('sim.games')}/${id}`;
    }
    return `${getRoute({ casino: 'casino.slots', live: 'casino.live' }[type])}/${translate(
        `casino.category.${id}.slug`,
    )}`;
}

export function getCasinoMiniCategoryRoute(category: CasinoCategory): string {
    return `${getRoute('casino.slots')}/mini/${translate(`casino.category.${category.id}.slug`)}`;
}

export function getCasinoCategorySubprovidersRoute(categoryType?): string {
    return `${getRoute({ casino: 'casino.slots', live: 'casino.live' }[categoryType || 'casino'])}/providers`;
}

export function getJackpotCategoryRoute(jackpot: Jackpot): string {
    return `${getRoute('casino.slots')}/jackpot/${jackpot.id}`;
}

export function getHiddenGameLabelsByCategory(category: CasinoCategory) {
    return (
        (category &&
            ((category.code === CasinoCategoryCode.NEW_GAMES && [GameLabels.NEW]) ||
                (category.code === CasinoCategoryCode.TOP_20 && [GameLabels.HOT]))) ||
        []
    );
}

export function getCasinoCategoryName(id): string {
    return translate(`${id === -2 ? 'payback booster' : 'name'}`, `casino.category.${id}`);
}

const getCasinoCategorySlug = (id): string => translate('slug', `casino.category.${id}`);

export function getCategoryTitle(categoryCode: string): string {
    const categoriesByCode = getStoreValue(casino.categoriesByCode);
    const category = categoriesByCode[categoryCode];

    const isRecentlyPlayed = categoryCode === CasinoCategoryCode.RECENTLY_PLAYED;

    const context = category?.isCustom ? 'ui.casino' : `casino.category.${category?.id}`;
    let key = isRecentlyPlayed ? 'recently-played' : 'name';

    if (category?.isCustom && !isRecentlyPlayed) {
        key = category?.name;
    }

    return translate(key, context);
}

export const lobbyLauncherTypeToCategory = {
    baccarat: 'live-baccarat',
    roulette: 'live-roulette',
    blackjack: 'live-blackjack',
    gameshow: 'gameshows',
} as const;

export async function loadFavoriteGames() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const gamesByGroupId = getStoreValue<{ [key: number]: CasinoGame }>(casino.gamesByGroupId);
    const favoriteGamesGroupIds = isAuthenticated ? await getFavoriteGames() : [];
    const favoriteGameIds = favoriteGamesGroupIds.map((gameGroupId) => gamesByGroupId[gameGroupId]?.id).filter(Boolean);

    casino.userPreferences.favoriteGameIds.set(favoriteGameIds);
}

export function updateFavoriteGamesCategory() {
    const categories = getStoreValue(casino.categories);
    const restCategories = categories.filter(({ code }) => code !== CasinoCategoryCode.FAVORITE_GAMES);
    const favoriteGamesCategory = getFavoriteCategory();
    casino.categories.set([...restCategories, ...(favoriteGamesCategory ? [favoriteGamesCategory] : [])]);
}

export async function refreshCategories(raceGames: CasinoGame[]) {
    casino.categories.set([]);
    await loadCasinoCategories();
    updateFavoriteGamesCategory();
    mapCasinoCategories(raceGames);
    mapCasinoGames();
}
