import { FinnishSSN } from 'finnish-ssn';
import Isikukood from 'isikukood';
import compact from 'lodash/compact';
import sum from 'lodash/sum';
import moment from 'moment';
import { parse } from 'query-string';
import { flushSync } from 'react-dom';
import {
    appleLogin,
    changeUserPassword,
    emailLogin,
    facebookLogin,
    fetchRegistrationCountries,
    getAuthData,
    getHasPassword,
    googleLogin,
    renewUserPassword,
    sendTwoFactorCode,
    tokenLogin,
    tryLogout,
} from '../microservices/auth';
import { loadKycUserSettings } from '../microservices/kyc';
import { socketAuthenticate, socketLogout } from '../microservices/pusher';
import { loadWallet } from '../microservices/wallet';
import { stores } from '../stores';
import { environment } from '../stores/environment/environment';
import { getStoreValue } from '../stores/store/utils';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from './analytics';
import { Country, Licence } from '@staycool/location';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from './date';
import { isFeatureAvailable } from './feature';
import { openKycModal } from './kyc';
import { languageByCountry } from './language';
import { logger } from './logger';
import { NativeMessageEventType, isMobileApp, sendNativeEvent } from './mobile-app';
import { setCamsIds } from './registration-cams/flow';
import { getLastActiveProductRoute, getRoute, isActiveRoute } from './router';
import { updateSiteLanguage } from './language';
import { storageRemove, storageSet } from './storage';
import { getDecodedToken, isTokenExpired, removeToken, setToken } from './token';
import { translate } from './translate';
import { FEATURE } from './types';
import { loadProfile } from './user';
import { getUserCountry } from './users/country';
import { LoginMethod } from './auth/types';
import { isWithLicence, LicenceRequirementSteps as Steps } from './licence';
import { LocalStorage } from './local-storage/types';
import { DEFAULT_KYC_SETTINGS } from './kyc/constants';
import isEmpty from 'lodash/isEmpty';

export const AUTH_SERVICE_ERROR_CODE = {
    GENERIC_AUTHENTICATION_ERROR: 3000,
    INACTIVE_USER: 3012,
    KYC_STATUS_PENDING: 3011,
    LEGACY_ERROR: 3010,
    LOGIN_BLOCKED: 3006,
    PASSWORD_USER_BEFORE: 14001,
    TWO_FACTOR_EMAIL_REQUIRED: 3009,
    TWO_FACTOR_SMS_REQUIRED: 3008,
    PASSWORD_RESET_SMS_REQUIRED: 3013,
    CLOSED_ACCOUNT_COOLDOWN: 3014,
} as const;

export async function authenticateWithEmail({ email, password }: { email: string; password: string }) {
    try {
        storageSet(LocalStorage.PREFER_LOGIN, LoginMethod.EMAIL);
        const response = await emailLogin({ email, password });
        return await handleAuthenticationSuccess(response, LoginMethod.EMAIL);
    } catch (response: any) {
        stores.twoFactor.email.set(email.toLowerCase());
        stores.twoFactor.country.set(response?.twoFactor?.country);
        handleAuthenticationFailure(response, LoginMethod.EMAIL);
    }
}

export async function authenticateWithApple(code: string, idToken: string) {
    try {
        const response = await appleLogin(code, idToken);
        return await handleAuthenticationSuccess(response, LoginMethod.APPLE);
    } catch (response: any) {
        handleAuthenticationFailure(response, LoginMethod.APPLE);
    }
}

export async function authenticateWithFacebook(accessToken: string) {
    try {
        storageSet(LocalStorage.PREFER_LOGIN, LoginMethod.FACEBOOK);
        const response = await facebookLogin(accessToken);
        return await handleAuthenticationSuccess(response, LoginMethod.FACEBOOK);
    } catch (response: any) {
        handleAuthenticationFailure(response, LoginMethod.FACEBOOK);
    }
}

export async function authenticateWithGoogle(code: string) {
    try {
        storageSet(LocalStorage.PREFER_LOGIN, LoginMethod.GOOGLE);
        const { GOOGLE_CLIENT_ID } = getStoreValue(environment);
        const response = await googleLogin({ clientId: GOOGLE_CLIENT_ID, code, redirectUri: window.location.origin });
        return await handleAuthenticationSuccess(response, LoginMethod.GOOGLE);
    } catch (response: any) {
        handleAuthenticationFailure(response, LoginMethod.GOOGLE);
    }
}

export async function authenticateWithLoginToken(token: string, loginMethod: LoginMethod) {
    try {
        const response = await tokenLogin({ token });
        await setTokenAndAuthenticate(response.token);
        return { navigateTo: getLastActiveProductRoute() };
    } catch (error: any) {
        handleAuthenticationFailure(error, loginMethod);
    }
}

export async function changePassword(newPassword: string, oldPassword = '') {
    await changeUserPassword({ newPassword, oldPassword });
    stores.hasPassword.set(true);
}

export async function renewPassword({ newPassword, oldPassword, email }) {
    try {
        return await renewUserPassword({ newPassword, oldPassword, email });
    } catch (response: any) {
        handleAuthenticationFailure(response, LoginMethod.EMAIL);
    }
}

export async function loadHasPassword() {
    const response = await getHasPassword();
    stores.hasPassword.set(response);
}

export function getPayAndPlayMessage(type) {
    return {
        SUCCESS: translate('Your deposit was successful.', 'ui.account'),
        SUCCESS_BONUS: translate('Your deposit was successful. Bonus added.', 'ui.account'),
        FAIL_BONUS: translate('Your deposit was successful. Fail to add bonus.', 'ui.account'),
        HEADING: translate('Please complete the form below to start playing', 'ui.pay-and-play'),
    }[type];
}

export async function isValidatorsValid(validators: any[]) {
    return compact(await Promise.all(validators)).map(({ error }) => error).length === 0;
}

export function validateSwedishPersonalId(personalId) {
    if (!personalId) {
        return { isValidFormat: false };
    }
    // eslint-disable-next-line no-param-reassign
    personalId = personalId.toString();
    const yearMonthDay = personalId.substring(0, 8);
    const yearMonthDayFormat = 'YYYYMMDD';

    function isValidFormat() {
        return Boolean(personalId && personalId.match(/^(\d{12})$/));
    }

    function isValidChecksum() {
        const digits = personalId.split('').map((digit) => parseInt(digit));
        const checksum = calculateChecksum(digits);
        const expectedChecksum = digits.pop();
        return checksum === expectedChecksum;
    }

    function calculateChecksum(digits) {
        const checksumDigits = digits.slice(2, 11);

        const total = sum(
            checksumDigits.map((digit, index) => {
                const multiplier = index % 2 === 0 ? 2 : 1;
                const result = digit * multiplier;

                if (result < 10) {
                    return result;
                }

                return result - 10 + 1;
            }),
        );

        const lastDigit = parseInt(total.toString().split('').pop() as string);

        const difference = 10 - lastDigit;

        return difference < 10 ? difference : 0;
    }

    function isValidBirthday() {
        return moment(yearMonthDay, yearMonthDayFormat).isValid();
    }

    function isValidAge() {
        const years = moment(new Date(), yearMonthDayFormat).diff(yearMonthDay, 'years');
        return years >= 18;
    }

    return {
        isValidFormat: isValidFormat() && isValidChecksum() && isValidBirthday(),
        isValidAge: isValidAge(),
    };
}

export function validateChileanPersonalId(personalId) {
    if (!personalId) {
        return { isValidFormat: false };
    }

    const sanitizedPersonalId = sanitizeRut(personalId.toUpperCase());

    if (sanitizedPersonalId.length < 7 || sanitizedPersonalId.length > 9) {
        return { isValidFormat: false };
    }

    function sanitizeRut(rut) {
        const regex = /[^0-9K]+/g;
        return rut.replace(regex, '');
    }

    function standardizeRut(rut) {
        return `${rut.slice(0, rut.length - 1)}-${rut.slice(rut.length - 1)}`;
    }

    function getControlCharacter(value) {
        if (value === 10) {
            return 'K';
        }
        if (value === 11) {
            return '0';
        }
        return String(value);
    }

    function isValidFormat(id) {
        // https://www.vesic.org/english/blog-eng/net/verifying-chilean-rut-code-tax-number/
        const factors = [3, 2, 7, 6, 5, 4, 3, 2];
        while (id.length !== factors.length + 1) {
            // eslint-disable-next-line no-param-reassign
            id = `0${id}`;
        }
        let total = 0;
        for (let i = 0; i < 8; i += 1) {
            total += Number(id[i]) * factors[i];
        }
        const validationSum = 11 - (total % 11);
        const controlCharacter = getControlCharacter(validationSum);
        return id[id.length - 1] === controlCharacter;
    }

    return {
        isValidFormat: isValidFormat(sanitizedPersonalId),
        standardizedPersonalId: standardizeRut(sanitizedPersonalId),
    };
}

export function validateEstonianPersonalId(personalId) {
    const idCode = new Isikukood(personalId);

    return {
        isValidFormat: idCode.validate(),
        isValidAge: idCode.getAge() >= 18,
    };
}

export function validateFinnishPersonalId(personalId) {
    if (!FinnishSSN.validate(personalId)) {
        return { isValidFormat: false };
    }

    const idCode = FinnishSSN.parse(personalId);
    return {
        isValidFormat: idCode.valid,
        isValidAge: idCode.ageInYears >= 18,
    };
}

export async function setTokenAndAuthenticate(token) {
    setToken(token);

    if (isFeatureAvailable(FEATURE.EXTERNAL_WALLET)) {
        logger.info('AuthService', 'setTokenAndAuthenticate', 'skippin user and wallet loading for Wynnbet');
    } else {
        try {
            await loadProfile({ isThrowingOnError: true });
            await Promise.all([loadWallet(), loadKycUserSettings()]);
        } catch (error) {
            stores.wallet.set(undefined);
            stores.wallets.set([]);
            stores.user.set(undefined);
            stores.kyc.settings.set(DEFAULT_KYC_SETTINGS);
            throw error;
        }
    }

    trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.LOGIN);

    socketAuthenticate();
    flushSync(() => {
        stores.isAuthenticated.set(true);
    });
    setIsLoginFromBackOffice(token);
}

export function handleAuthenticationFailure(
    {
        appealId,
        gdprDownloadToken,
        kycToken,
        kycTokenExpiry,
        message,
        code,
        details,
        twoFactor,
        name,
        cams,
    }: AuthenticationFailureResponse,
    loginMethod: LoginMethod,
) {
    if (kycToken) {
        openKycModal(kycToken, kycTokenExpiry);
        stores.modals.isLoginModalOpen.set(false);
        return;
    }

    if (gdprDownloadToken) {
        stores.gdprDataDownloadToken.set(gdprDownloadToken);
        stores.modals.isLoginModalOpen.set(false);
        stores.modals.isAccountDataDownloadModalOpen.set(true);
        return;
    }

    if (appealId) {
        stores.appeal.id.set(appealId);
    }

    if (code === AUTH_SERVICE_ERROR_CODE.LOGIN_BLOCKED) {
        throw new Error(
            translate('login-blocked-until', 'ui.responsible-gaming', {
                loginBlockExpiryTime: getFormattedDate({
                    date: details?.loginBlockedUntil,
                    format: DATE_YEAR_TIME_FORMAT,
                    useDots: true,
                }),
            }),
        );
    } else if (code === 3007) {
        throw new Error(
            translate('login-duration-has-been-exceeded', 'ui.responsible-gaming', {
                durationLimitExpiryTime: getFormattedDate({
                    date: details?.loginBlockedUntil,
                    format: DATE_YEAR_TIME_FORMAT,
                    useDots: true,
                }),
            }),
        );
    } else if (code === AUTH_SERVICE_ERROR_CODE.TWO_FACTOR_SMS_REQUIRED && twoFactor) {
        return forceTwoFactorSms(twoFactor);
    } else if (code === AUTH_SERVICE_ERROR_CODE.TWO_FACTOR_EMAIL_REQUIRED && twoFactor) {
        return forceTwoFactorEmail(twoFactor);
    } else if (code === AUTH_SERVICE_ERROR_CODE.LEGACY_ERROR && message) {
        throw new Error(translate(message.toString(), 'ui.account'));
    } else if (code === AUTH_SERVICE_ERROR_CODE.KYC_STATUS_PENDING && cams) {
        const { token, userId } = cams;
        if (token && userId) {
            setCamsIds(token, userId);
        }
        throw new Error(code.toString());
    } else if (code === AUTH_SERVICE_ERROR_CODE.INACTIVE_USER && cams) {
        const { token, userId, inPerson, socialSecurityNumber } = cams;
        if (token && userId) {
            setCamsIds(token, userId, true, inPerson, socialSecurityNumber);
        }
        throw new Error(code.toString());
    } else if (code === AUTH_SERVICE_ERROR_CODE.CLOSED_ACCOUNT_COOLDOWN && message) {
        throw new Error(
            translate(message as string, undefined, {
                date: getFormattedDate({
                    date: details?.closedAccountCooldownUntil,
                    format: DATE_YEAR_TIME_FORMAT,
                    useDots: true,
                }),
            }),
        );
    } else if (
        code === AUTH_SERVICE_ERROR_CODE.GENERIC_AUTHENTICATION_ERROR &&
        name === 'accountClosedError' &&
        message
    ) {
        throw new Error(translate(message.toString(), 'ui.account'));
    } else if (code === AUTH_SERVICE_ERROR_CODE.PASSWORD_USER_BEFORE) {
        throw new Error(translate('Password has been used before. Please enter a different password!', 'ui.account'));
    }

    throw new Error(translate(`Failed to authenticate with {{ loginMethod }}`, 'auth.login', { loginMethod }));
}

export async function handleAuthenticationSuccess(response, loginMethod) {
    const {
        token,
        email,
        registrationToken,
        firstName,
        lastName,
        alias,
        askSmsVerification,
        country,
        phonePrefix,
        phoneNumber,
        gender,
        birthDate,
        address,
        id,
    } = response;

    const isLoginFromBackoffice = Boolean(getBackofficeLoginToken());

    if (registrationToken && !isLoginFromBackoffice) {
        stores.modals.isLoginModalOpen.set(false);

        stores.registration.form.set((registrationForm) => {
            registrationForm.email = email || registrationForm.email;
            registrationForm.firstName = firstName;
            registrationForm.lastName = lastName;
            registrationForm.alias = alias;
            registrationForm.socialMediaRegistrationToken = registrationToken;
            registrationForm.country = country || registrationForm.country;
            registrationForm.gender = gender || registrationForm.gender;
            registrationForm.birthDate = birthDate || registrationForm.birthDate;
            registrationForm.address = address || registrationForm.address;
            registrationForm.phoneNumber = phoneNumber || registrationForm.phoneNumber;
        });

        return { navigateTo: isFeatureAvailable(FEATURE.REGISTRATION) ? getRoute('registration') : undefined };
    }
    if (askSmsVerification && !isLoginFromBackoffice) {
        stores.verification.data.set({
            temporaryToken: token,
            country,
            phoneNumber,
            phonePrefix,
            email,
            userId: id,
        });
        stores.modals.isLoginFormOpen.set(false);
        stores.modals.isLoginModalOpen.set(true);
        stores.verification.isVerificationActive.set(true);

        if (isActiveRoute(getRoute('registration'))) {
            return { navigateTo: getLastActiveProductRoute() };
        }
    } else if (token) {
        await setTokenAndAuthenticate(token);
        stores.modals.isLoginModalOpen.set(false);

        const user = getStoreValue(stores.user);
        const language = getStoreValue(stores.language);
        if (user && user.language !== language) {
            if (isUserValidForAnnualReport()) {
                storageSet(LocalStorage.SHOW_ANNUAL_REPORT, true);
            }
            updateSiteLanguage(user.language);
        }

        if (isActiveRoute(getRoute('registration'))) {
            return { navigateTo: getLastActiveProductRoute() };
        }
    } else {
        throw new Error(translate(`Failed to authenticate with {{ loginMethod }}`, 'auth.login', { loginMethod }));
    }
    return null;
}

export function isUserValidForAnnualReport() {
    return isWithLicence(Licence.SWEDEN);
}

function forceTwoFactorSms({ pinId, reason, safePhone, shouldAskToTrustDevice }: TwoFactor) {
    stores.twoFactor.smsPinId.set(pinId);
    stores.twoFactor.reason.set(reason);
    stores.twoFactor.safePhone.set(safePhone);
    stores.twoFactor.isTwoFactorSmsActive.set(true);
    stores.twoFactor.isTwoFactorEmailActive.set(false);
    stores.twoFactor.shouldAskToTrustDevice.set(shouldAskToTrustDevice);
    stores.modals.isLoginFormOpen.set(false);
}

function forceTwoFactorEmail({ reason, shouldAskToTrustDevice }: TwoFactor) {
    stores.twoFactor.reason.set(reason);
    stores.twoFactor.isTwoFactorEmailActive.set(true);
    stores.twoFactor.shouldAskToTrustDevice.set(shouldAskToTrustDevice);
    stores.modals.isLoginFormOpen.set(false);
}

export async function startLoginTwoFactorAuth(email: string) {
    try {
        storageSet(LocalStorage.PREFER_LOGIN, LoginMethod.EMAIL);
        const authenticationResponse = await sendTwoFactorCode(email);
        return await handleAuthenticationSuccess(authenticationResponse, LoginMethod.EMAIL);
    } catch (response: any) {
        stores.twoFactor.email.set(email);
        stores.twoFactor.country.set(response?.twoFactor?.country);
        handleAuthenticationFailure(response, LoginMethod.EMAIL);
        return;
    }
}

export function getBackofficeLoginToken() {
    const hasBackofficeLoginToken = window.location.search.includes('imp_token');
    return hasBackofficeLoginToken ? (parse(window.location.search).imp_token as string) : undefined;
}

export async function logout(logoutReason?: string, softLogout = false, isLogoutSilencedInMobileApps = false) {
    if (isMobileApp()) {
        sendNativeEvent({
            type: NativeMessageEventType.LOGOUT,
            shouldDisplayPopup: isLogoutSilencedInMobileApps,
            ...(logoutReason && { reason: logoutReason }),
        });
    }

    if (isMobileApp() && isLogoutSilencedInMobileApps) {
        return;
    }
    const token = getStoreValue(stores.token);
    const user = await loadProfile();
    storageSet('isLogoutInProgress', true);

    if (token && !isTokenExpired() && !softLogout) {
        try {
            await tryLogout(logoutReason);
        } catch (error) {
            logger.error('AuthService', 'logout', error);
        }
    }

    setLogoutPopupMessage(user);
    stores.user.set(undefined);
    removeToken(softLogout);
    socketLogout();

    stores.wallet.set(undefined);
    stores.wallets.set([]);
    storageSet('userAlias', undefined);
}

export function isVisitorFromCountry(country: Country) {
    const ipCountry = getStoreValue(stores.ipCountry);
    const language = getStoreValue(stores.language);
    return ipCountry === country || languageByCountry[country] === language;
}

function setIsLoginFromBackOffice(token) {
    const { bo_user_id } = getDecodedToken(token);
    if (Boolean(bo_user_id)) {
        storageSet(LoginMethod.BO_LOGIN, true);
    } else {
        storageRemove(LoginMethod.BO_LOGIN);
    }
}

function setLogoutPopupMessage(user) {
    if (user?.closed) {
        if (user.closeMessage === 'SENT_EMAIL') {
            window.location.search = `?error=${translate(
                'Your account has been closed. We have sent you an email with further details',
                'ui.account',
            )}`;
        } else {
            window.location.search = `?error=${translate(
                'Your account has been closed. Please contact support',
                'ui.account',
            )}`;
        }
    }
}

export async function getRegistrationCountries() {
    const { countryCurrencies, registrationCountries } = await fetchRegistrationCountries();

    const countries = registrationCountries.map((country) => ({
        ...country,
        name: country.name && translate(country.name, 'ui.countries'),
        allowedCurrencies: country.code in countryCurrencies ? countryCurrencies[country.code] : [],
    }));

    const country = countries.find((country) => country.code === getUserCountry()) || countries[0];

    return {
        phonePrefix: country.phonePrefix,
        countryCode: country.code,
        currency: country.currency,
        countries: [...countries],
        phoneCodeByCountryCode: countries.reduce((phoneCodeByCountryCode, country) => {
            phoneCodeByCountryCode[country.code] = country.phonePrefix;
            return phoneCodeByCountryCode;
        }, {} as Record<Country, string>),
        allowedCurrenciesByCountryCode: countryCurrencies,
    };
}

export async function getLinkedAccountsInfo() {
    const authorizationInfo = await getAuthData();

    return {
        isAppleLinked: Boolean(authorizationInfo.apple),
        isFacebookLinked: Boolean(authorizationInfo.facebook),
        isGoogleLinked: Boolean(authorizationInfo.google),
    };
}

export async function getIs2FAEnabled() {
    const authorizationInfo = await getAuthData();
    return authorizationInfo.is_2faEnabled;
}

type AuthenticationFailureResponse = {
    name?: string;
    appealId?: string;
    gdprDownloadToken?: string;
    kycToken?: string;
    kycTokenExpiry?: string;
    message?:
        | string
        | {
              pin_id?: string;
              reason?: string;
              safePhone?: string;
              enableEmailFallback?: string;
              login_duration_blocked?: string;
              limit_end?: Date;
              login_blocked_until?: Date;
          };
    code?: number;
    details?: {
        closedAccountCooldownUntil: string;
        loginBlockedUntil: Date;
    };
    twoFactor?: TwoFactor;
    cams?: {
        token: string;
        userId: string;
        inPerson: boolean;
        socialSecurityNumber: string;
    };
};

type TwoFactor = { pinId: string; reason: string; safePhone: string; shouldAskToTrustDevice?: boolean };

export function isDefaultEmailRegistrationCountry(countryCode) {
    const countries = [Country.ICELAND];
    return countries.includes(countryCode);
}

/**
 * Show login option depending on platform
 * @example isMobileApp (native app)
 * send javascript event caught/handled by native app - no actions from web needed
 * @example web page
 * change store - web login modal window should appear
 */
export function requestLogin(urlToNavigate?: string) {
    if (isMobileApp()) {
        return sendNativeEvent({ type: NativeMessageEventType.LOGIN });
    }
    urlToNavigate && stores.latestURLPromisedToNavigate.set(urlToNavigate);
    stores.modals.isLoginModalOpen.set(true);
}

export function geLicenceRequirementSteps() {
    const user = getStoreValue(stores.user);
    const askLossLimit = getStoreValue(stores.responsibleGaming.askLossLimit);
    const askDepositLimit = getStoreValue(stores.responsibleGaming.askDepositLimit);

    const steps: Steps[] = [];

    if (user?.askPersonalId) {
        steps.push(Steps.PERSONAL_ID);
    }

    if (Boolean(user?.isProfileReviewRequired && !isEmpty(user?.requiredFields))) {
        steps.push(Steps.REQUIRED_FIELDS);
    }

    if (askDepositLimit) {
        steps.push(Steps.DEPOSIT_LIMIT);
    }

    if (askLossLimit) {
        steps.push(Steps.LOSS_LIMIT);
    }

    return steps;
}
