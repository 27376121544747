import React from 'react';
import WrapperButton from './styles-button';
import WrapperTab from './styles-tab';
import WrapperCard from './styles-card';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import { getRoute, isActiveRoute } from '../../../services/router';
import { filterStyleProps } from '../../../styles/utils';
import UiBadge from '../badge/UiBadge';

type ButtonTypes = 'button' | 'menu' | 'tab' | 'card';

interface CommonProps
    extends React.PropsWithChildren,
        React.StyleHTMLAttributes<HTMLButtonElement>,
        Omit<React.HTMLProps<HTMLButtonElement>, 'size'> {
    type?: ButtonTypes;
    size?: 'tiny' | 'small' | 'large' | 'default';
    block?: boolean;
    selected?: boolean;
    isLoading?: boolean;
    route?: string;
    url?: string;
    dataTest?: string;
    icon?: React.ReactNode;
    badge?: number;
    openNewTab?: boolean;
}
interface ButtonProps extends CommonProps {
    type: 'button';
    color?: 'default' | 'primary' | 'warning' | 'ghost' | 'outline';
    isFormSubmitButton?: boolean;
    iconPosition?: 'left' | 'right';
}
interface TabProps extends CommonProps {
    type: 'tab' | 'menu';
    isTwoToneTheme?: boolean;
    lowercase?: boolean;
}
interface CardProps extends CommonProps {
    type: 'card';
    isOnSurface?: boolean;
}

type Props = CommonProps | ButtonProps | TabProps | CardProps;

export default function UiButton({
    isLoading,
    icon,
    children,
    route,
    url,
    disabled,
    block,
    selected,
    onClick,
    onMouseOver,
    onMouseOut,
    badge,
    dataTest,
    type = 'button',
    size = 'default',
    openNewTab,
    ...rest
}: Props) {
    const isDisabled = isLoading || disabled;
    const onClickHandler = onClick && !isDisabled ? onClick : () => {};
    const onMouseOverHandler = onMouseOver && !isDisabled ? onMouseOver : () => {};
    const onMouseOutHandler = onMouseOut && !isDisabled ? onMouseOut : () => {};
    const showBadge = Boolean(badge);

    function getLoader() {
        return isLoading && <UiDotsLoader />;
    }

    function getButton(type: ButtonTypes, isLink?: boolean) {
        if (type === 'card') {
            const { isOnSurface, ...props } = rest as CardProps;

            return (
                <WrapperCard
                    {...filterStyleProps({ ...props })}
                    data-test={dataTest}
                    size={size}
                    $block={block}
                    onClick={onClickHandler}
                    $isOnSurface={isOnSurface}
                    selected={selected ?? isActiveRoute(url, false)}
                    $badge={badge}
                    as={isLink ? 'span' : 'button'}
                >
                    {getLoader()}
                    {icon && !showBadge && !isLoading && <span className="button-icon">{icon}</span>}
                    {showBadge && !isLoading && <span className="button-badge">{badge}</span>}
                    {children && !isLoading && <span className="button-text">{children}</span>}
                </WrapperCard>
            );
        }

        if (type === 'tab' || type == 'menu') {
            const { lowercase, isTwoToneTheme, ...props } = rest as TabProps;

            return (
                <WrapperTab
                    {...filterStyleProps({ ...props })}
                    type="button"
                    onClick={onClickHandler}
                    $size={size}
                    $type={type}
                    $lowercase={lowercase}
                    $isTwoToneTheme={isTwoToneTheme}
                    selected={selected}
                    as={isLink ? 'span' : 'button'}
                >
                    {getLoader()}
                    {icon && !isLoading && <span className="icon">{icon}</span>}
                    {!isLoading && <UiBadge badge={badge} className="button-badge" />}
                    {children && !isLoading && (
                        <span className="text">
                            <span className="content">{children}</span>
                        </span>
                    )}
                </WrapperTab>
            );
        }

        const { iconPosition = 'right', isFormSubmitButton, color = 'default', ...props } = rest as ButtonProps;
        const buttonType = isFormSubmitButton ? 'submit' : 'button';

        return (
            <WrapperButton
                {...filterStyleProps({ ...props })}
                data-test={dataTest}
                type={buttonType}
                color={color}
                disabled={isDisabled}
                $block={block}
                size={size}
                selected={selected}
                onClick={onClickHandler}
                onMouseOver={onMouseOverHandler}
                onMouseOut={onMouseOutHandler}
                as={isLink ? 'span' : 'button'}
            >
                {getLoader()}
                {<UiBadge badge={badge} className="button-badge" />}
                {icon && !isLoading && iconPosition === 'left' && <span className="button-icon">{icon}</span>}
                {children && !isLoading && <span className="button-text">{children}</span>}
                {icon && !isLoading && iconPosition === 'right' && <span className="button-icon">{icon}</span>}
            </WrapperButton>
        );
    }

    function wrapToLink(element: React.ReactNode) {
        return (
            <UiNavLink
                exact
                to={route ? getRoute(route) : url}
                style={{ display: block ? 'block' : 'inline-block' }}
                $openNewTab={openNewTab}
            >
                {element}
            </UiNavLink>
        );
    }

    if ((route || url) && !isDisabled) {
        return wrapToLink(getButton(type, true));
    } else {
        return getButton(type);
    }
}
