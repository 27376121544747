import React from 'react';
import * as Sentry from '@sentry/browser';

export class ErrorBoundary extends React.PureComponent<any, { error: any }> {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.error) {
            return null;
        }

        return this.props.children;
    }
}

export const withErrorBoundary = (ComponentThatMightFail) => (props) =>
    (
        <ErrorBoundary>
            <ComponentThatMightFail {...props} />
        </ErrorBoundary>
    );
