import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.iframe`
    width: 100%;
    height: calc(100vh - var(--header-height));
    @media ${mediaQuery.isSmallerThanLaptop} {
        height: calc(100vh - var(--header-height) - var(--mobile-nav-height));
    }
`;
